<template>
    <div>
        <b-modal :id="modalId" :title="flightTitle" @close="closeModal" no-close-on-backdrop>
            <b-row>
                <b-col lg="12">
                    <b-form-group
                        label="Airline"
                        label-for="basicSelectLg"
                        :horizontal="true"
                        value="airline">

                        <b-form-select
                            :class="formData.airline === '' ? 'border border-danger' : ''"
                            :plain="true"
                            :options="airlines.map(a => a.name)"
                            v-model="formData.airline" @change="mapAirlineLogo">
                        </b-form-select>
                    </b-form-group>

                    <b-form-group
                        label="Arriving or Departing"
                        label-for="basicSelectLg"
                        :horizontal="true">
                        <b-form-select
                            :class="formData.type === '' ? 'border border-danger' : ''"
                            :plain="true"
                            :options="['Arriving','Departing']"
                            v-model="formData.type"
                            value="Please select arriving or departing">
                        </b-form-select>
                    </b-form-group>

                    <b-form-group v-if="formData.type === 'Arriving'"
                                  label="Baggage Claim Number"
                                  label-for="basicSelectLg"
                                  :horizontal="true">
                        <b-form-select
                            :class="formData.baggageclaimnumber === '' ? 'border border-danger' : ''"
                            :plain="true"
                            :options="['1','2', '3', '4']"
                            v-model="formData.baggageclaimnumber"
                            value="Please select baggage claim number">
                        </b-form-select>
                    </b-form-group>


                    <b-form-group
                        label="Flight"
                        label-for="basicText"
                        :horizontal="true">
                        <b-form-input :class="formData.flight === '' ? 'border border-danger' : ''"
                                      id="basicText" type="text" placeholder="Example: LL-1234"
                                      v-model="formData.flight"></b-form-input>
                    </b-form-group>

                    <b-form-group
                        label="Gate Selector"
                        label-for="basicSelectLg"
                        :horizontal="true">
                        <b-form-select :class="formData.gate === '' ? 'border border-danger' : ''"
                                       :plain="true"
                                       :options="['1','2', '3', '4']"
                                       value="Please select a gate"
                                       v-model="formData.gate">
                        </b-form-select>
                    </b-form-group>

                    <b-form-group
                        label="Status"
                        label-for="basicSelectLg"
                        :horizontal="true">
                        <b-form-select :class="formData.status === '' ? 'border border-danger' : ''"
                                       :plain="true"
                                       :options="['On Time','Arrived', 'Delayed','Boarding', 'Departed', ]"
                                       value="Please select a status"
                                       v-model="formData.status">
                        </b-form-select>
                    </b-form-group>

                    <b-form-group
                        label="Departing"
                        label-for="basicText2"
                        :horizontal="true">
                        <b-form-input
                            :class="formData.departing === '' ? 'border border-danger' : ''"
                            id="basicText2" type="text" placeholder="Example: RFD" v-model="formData.departing"
                            @input="formData.departing = formData.departing.toUpperCase()"></b-form-input>
                    </b-form-group>

                    <b-form-group
                        label="Departure Time"
                        label-for="basicText"
                        :horizontal="true">
                        <date-picker
                            :class="formData.departuretime === '' || formData.departuretime === null ? 'border border-danger' : ''"
                            v-model="formData.departuretime" :config="options"></date-picker>
                    </b-form-group>

                    <b-form-group
                        label="Arriving"
                        label-for="basicText3"
                        :horizontal="true">
                        <b-form-input
                            :class="formData.arriving === '' ? 'border border-danger' : ''"
                            id="basicText3" type="text" placeholder="Example: RFD" v-model="formData.arriving"
                            @input="formData.arriving = formData.arriving.toUpperCase()"></b-form-input>
                    </b-form-group>

                    <b-form-group
                        label="Arrival Time"
                        label-for="basicText"
                        :horizontal="true">
                        <date-picker
                            :class="formData.arrivaltime === ''  || formData.arrivaltime === null ? 'border border-danger' : ''"
                            v-model="formData.arrivaltime" :config="options"></date-picker>
                    </b-form-group>

                    <b-form-group
                        label="Roll In"
                        label-for="basicText"
                        :horizontal="true">
                        <date-picker
                            :class="formData.rollIn === '' || formData.rollIn === null ? 'border border-danger' : ''"
                            v-model="formData.rollIn" :config="options"></date-picker>
                    </b-form-group>

                    <b-form-group
                        label="Roll Out"
                        label-for="basicText"
                        :horizontal="true">
                        <date-picker
                            :class="formData.rollOut === '' || formData.rollOut === null ? 'border border-danger' : ''"
                            v-model="formData.rollOut" :config="options"></date-picker>
                    </b-form-group>

                </b-col>
            </b-row>

            <template slot="modal-footer">
                <b-button class="btn-sm" @click="closeModal">Cancel</b-button>
                <b-button class="btn btn-primary btn-sm btn" variant="primary" :disabled="isReady()" @click="returnObject">Submit</b-button>
            </template>

        </b-modal>
    </div>
</template>

<script>
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import fcr from "@/services/fcr";
import blackhole from "@/services/blackhole";

export default {
    name: "fids_form",
    props: {
        id: Object,
        value: Object,
        modalId: String,
        flightTitle: String
    },
    data() {
        return {
            formData: Object.assign({}, this.value),
            logoId: '',
            airlines: [],
            flights: [],
            response: "",
            options: {
                format: 'MM/DD/YYYY h:mm a',
                useCurrent: true,
            },
            input: {
                type: "",
            }
        }
    },
    components: {
        datePicker,
    },
    created() {
        fcr.readAirlines().then(response => {
            this.airlines = response.data;
        }, error => {
            console.error(error);
        });
    },
    methods: {
        mapAirlineLogo(airlineName) {
            for (let airline of this.airlines) {
                if (airline.name === airlineName) {
                    this.logoId = airline.image;
                    return this.logo(airline.image);
                }
            }
        },
        logo(logoId) {
            if (!logoId) {
                return "";
            } else {
                return blackhole.readImage(logoId);
            }
        },
        isReady() {
            if (this.formData.airline === "" || this.formData.flight === "" || this.formData.gate === "" || this.formData.status === ""
                || this.formData.departing === "" || this.formData.arriving === "" || this.formData.departuretime === "" || this.formData.arrivaltime === ""
                || this.formData.departuretime === null || this.formData.arrivaltime === null || this.formData.rollIn === null || this.formData.rollOut === null
                || this.formData.rollIn === "" || this.formData.rollOut === "" || this.formData.type === "") {
                return true;
            } else {
                return this.formData.type === "Arriving" && this.formData.baggageclaimnumber === "";
            }
        },
        handleFileUpload() {
            this.upload.dataBlackhole = this.$refs.files.files;

        },
        returnObject() {
            this.$emit("sendObject", this.formData);
            this.$bvModal.hide(this.modalId);
            if(!this.value._id){
                this.formData = Object.assign({}, this.value);
            }
        },
        closeModal() {
            this.$bvModal.hide(this.modalId);
            this.formData = Object.assign({}, this.value);
        }
    }
}
</script>

<style scoped>

</style>
